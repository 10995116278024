import { SvgIconProps } from "@material-ui/core"

import { ISmokingDetection } from "src/data/homes/types/homeTypes"
import { colorScale, colorsV2 } from "src/ui/colors"
import SmokingDetectionFilledIcon from "src/ui/icons/cigarette-detection-filled.svg"

export function SmokingDetectionIcon({
  state,
  status,
  iconProps,
}: {
  state: ISmokingDetection["state"] | undefined
  status?: ISmokingDetection["status"]
  iconProps?: SvgIconProps
}) {
  if (state === "enabled") {
    if (status === "alerting") {
      return (
        <SmokingDetectionFilledIcon
          fill={colorsV2.systemErrorLight}
          color={colorsV2.systemErrorDark}
          {...iconProps}
        />
      )
    }

    return (
      <SmokingDetectionFilledIcon
        fill={colorsV2.systemInfoLight}
        color={colorsV2.systemInfoDark}
        {...iconProps}
      />
    )
  }

  return (
    <SmokingDetectionFilledIcon
      fill={colorsV2.neutralDark}
      color={colorScale.koti[400]}
      {...iconProps}
    />
  )
}
